"use strict";

/**
 * Created on April 14, 2020
 * @author Elias Melgaço
 */

(function () {
  'use strict';

  var languageContainerLanguage = {
    header: {
      title: 'Defina os idiomas da pesquisa'
    }
  };
  window.moduleExport({
    languageContainerLanguage: languageContainerLanguage
  }, typeof module !== 'undefined' && module);
})();